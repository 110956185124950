import React from "react"

import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Breadcrumbs from "../components/breadcrumb"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Page Not Found" />
    <Row>
      <Col id="page-title" className="col col-12">
        <h1 className="title">Page Not Found</h1>
      </Col>
    </Row>
    <Breadcrumbs />
    <Row id="content">
      <Col>
        <Row>
          <div>
            <p>The requested page could not be found.</p>
            <p>
              Please use the site navigation or search to find the page you are
              looking for.
            </p>
          </div>
        </Row>
      </Col>
    </Row>
  </Layout>
)

export default NotFoundPage
